<template>
  <div>
    <media :multiplatform-user-manager="true" />
  </div>
</template>

<script>
import media from '../../contents/media/Media.vue'

export default {
  components: {
    media,
  },
  setup() {

  },
  data() {
    return {

    }
  },
  watch: {

  },
  methods: {

  },
}
</script>
<style scoped lang="scss">

</style>
