<template>
  <div>
    <b-card>
      <b-card-header class="d-flex justify-content-start mb-1">
        <span
          class="font-weight-bold"
          style="color: #fefefe; font-size: 16px"
        >{{ $t("administrators.usIn") }} </span>
      </b-card-header>
      <b-card-body>
        <div>
          <div>
            <div
              v-if="isProfileUser"
              class="d-flex justify-content-start "
            >
              <!-- Antigua IMAGEN  -->
              <b-img
                v-if="imageSrc || avatar"
                ref="previewEl"
                rounded
                height="125"
                style="background-color: #31375a;"
                :src="imageSrc ? imageSrc : avatar"
                @error="handleImageUserError"
              />
              <b-img
                v-else
                ref="previewEl"
                rounded
                height="125"
                style="background-color: #31375a;"
                :src="srcImageCard"
                @error="handleImageUserError"
              />
              <div class="d-flex align-items-end pl-1">
                <b-button
                  v-b-tooltip.hover.right="infoImg"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mr-75"
                  @click="selectFile"
                >
                  <feather-icon
                    icon="UploadIcon"
                    size="16"
                  />
                  {{ $t("media.upload") }}
                </b-button>
                <b-form-file
                  ref="refInputEl"
                  v-model="imageData"
                  accept="image/jpeg, image/png"
                  :hidden="true"
                  plain
                  @change="handleFileChange"
                  @input="changeForm()"
                />
                <b-link
                  v-if="imageSrc || avatar"
                  v-b-tooltip.hover.right="$t('dataGeneric.delete')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-50"
                  @click="removeAvatar"
                >
                  <feather-icon
                    class="text-danger"
                    icon="Trash2Icon"
                    size="18"
                  />
                </b-link>
                <!-- <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-danger"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="deleteFile()"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                  />
                  Eliminar
                </b-button> -->
              </div>
            </div>
          </div>
          <hr>
          <div>
            <validation-observer ref="simpleRules">
              <b-form
                class="mt-2"
                @input="changeForm()"
                @submit.prevent="validationFormInfo()"
              >
                <b-row>
                  <b-col sm="6">
                    <b-form-group
                      :label="$t('administrators.user')"
                      label-for="mc-user-name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="User Name"
                        rules="required"
                      >
                        <b-form-input
                          id="mc-user-name"
                          v-model="username"
                          disabled
                          autocomplete="new-password"
                          :placeholder="$t('administrators.user')"
                          maxlength="75"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      :label="$t('administrators.email')"
                      label-for="mc-user-email"
                    >
                      <validation-provider
                        v-slot="validationContext"
                        name="User Email"
                        rules="email"
                      >
                        <b-form-input
                          id="mc-user-email"
                          v-model="email"
                          autocomplete="new-password"
                          :placeholder="$t('administrators.email')"
                          maxlength="254"
                        />
                        <small class="text-danger">{{
                          checkEmail(validationContext.failedRules)
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      :label="$t('administrators.name')"
                      label-for="mc-first-name"
                    >
                      <b-form-input
                        id="mc-first-name"
                        v-model="firstName"
                        :placeholder="$t('administrators.name')"
                        autocomplete="new-password"
                        maxlength="30"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      :label="$t('administrators.lname')"
                      label-for="mc-last-name"
                    >
                      <b-form-input
                        id="mc-last-name"
                        v-model="lastName"
                        autocomplete="new-password"
                        :placeholder="$t('administrators.lname')"
                        maxlength="30"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="isProfileUser"
                    sm="12"
                  >
                    <b-form-group
                      :label="$t('resources.tableHeader.description')"
                      label-for="mc-description"
                    >
                      <b-form-textarea
                        id="mc-description"
                        v-model="description"
                        placeholder="Introduce la descripción"
                        rows="3"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="d-flex justify-content-end">
                  <b-button
                    variant="success"
                    :disabled="disabled"
                    class="mb-1 mb-sm-0  float-right mt-2"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="submit"
                  >
                    <feather-icon icon="SaveIcon" />
                    {{ $t("dataGeneric.save") }}
                  </b-button>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getUserData } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import router from '@/router'

// import {
//   base64Encode,
// } from '@/store/functions'
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BImg,
  VBTooltip,
  BFormTextarea,
  BCardHeader,
  BCardBody,
  BLink,

} from 'bootstrap-vue'
import { checkPermissions, messageError, showToast } from '@/store/functions'
import axios from '@axios'

const noImgUser = require('@/assets/images/backend/user.svg')

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BCardHeader,
    BCardBody,
    BLink,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  props: {
    view: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      srcImageCard: noImgUser,
      required,
      disabled: true,
      loginUser: getUserData(),
      isMultiplatformUser: false,
      isSuperuser: false,
      isProfileUser: false,
      infoImg: this.$t('allowImage2'),
      fileUrl: null,
      profileFile: null, // Archivo seleccionado
      uploadedUrl: null, // URL de la imagen subida
      imgNoDisponible: true,
      imageSrc: null,
      permissionGroups: [],
      userData: null,
      id: router.currentRoute.params.id,
      password: null,
      description: null,
      avatar: null,
      imageData: null,
      username: null,
      email: null,
      firstName: null,
      lastName: null,
      avatarRemoved: false,
    }
  },
  watch: {
    imageOrigin() {
      this.imageDataUrl = this.imageOrigin
    },
  },
  mounted() {
    if (this.view === 'admin') {
      this.isProfileUser = true
      this.getProfileUser()
    } else {
      this.isMultiplatformUser = this.loginUser.isMultiplatformUser
      this.isSuperuser = this.loginUser.isSuperuser
      this.isProfileUser = this.loginUser.isProfileUser
      if (this.isMultiplatformUser) {
        this.getMultiplatformUser()
      }
      if (this.isSuperuser) {
        this.getcustomUser()
      }
      if (this.isProfileUser) {
        this.getProfileUser()
      }
    }
  },
  methods: {
    validationFormInfo() {
      return new Promise(resolve => {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.mutateUser()
          }
        }).catch(err => { console.log(err) })
      })
    },
    getcustomUser() {
      const query = `{
        allCustomUsers(id: "${this.id}") {
          edges {
            node {
              id
              username
              firstName
              lastName
              email                          
              
            
          }
        }
        }
      }`
      axios.post('', { query }).then(result => {
        messageError(result, this)

        this.userData = result.data.data.allCustomUsers.edges[0].node
        this.username = this.userData.username
        this.firstName = this.userData.firstName
        this.lastName = this.userData.lastName
        this.email = this.userData.email
      }).catch(err => {
        console.log(err)
      })
    },
    getMultiplatformUser() {
      const query = `{
        allMultiplatformUsers(id: "${this.id}") {
          edges {
            node {
              id
              username
              firstName
              lastName
              email       
              files{
                totalCount
                  edges {
                    node {
                      id
                      name
                      type
                      staticUrl
                      createdAt
                      mediaDuration
                      fileUrl
                      client{
                        id
                        name
                      }
                      
                    }
                  }
              }       
          }
        }
        }
      }`
      axios.post('', { query }).then(result => {
        messageError(result, this)

        this.userData = result.data.data.allMultiplatformUsers.edges[0].node
        this.avatar = this.userData.avatar
        this.username = this.userData.username
        this.firstName = this.userData.firstName
        this.lastName = this.userData.lastName
        this.email = this.userData.email

        if (!checkPermissions('users.menu_cms_profile')) {
          let authorized = false
          if (this.userData.id === this.loginUser.id) authorized = true
          if (!authorized) {
            router.push({ name: 'misc-not-authorized' })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getProfileUser() {
      const query = `{
        allProfiles(id: "${this.id}") {
          edges {
            node {
              id
              username
              firstName
              lastName
              email
              isActive
              dateJoined
              avatar
              description
              subgroup {
                id
                name
              }
              lastLogin                          
                groups {
                  edges {
                    node {
                      id
                      name
                }
              }
            }
          }
        }
        }
      }`
      axios.post('', { query }).then(result => {
        messageError(result, this)

        this.userData = result.data.data.allProfiles.edges[0].node
        this.avatar = this.userData.avatar
        this.username = this.userData.username
        this.firstName = this.userData.firstName
        this.lastName = this.userData.lastName
        this.email = this.userData.email
        this.description = this.userData.description

        if (!checkPermissions('users.menu_cms_profile')) {
          let authorized = false
          if (this.userData.id === this.loginUser.id) authorized = true
          if (!authorized) {
            router.push({ name: 'misc-not-authorized' })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getGroups() {
      const query = `{
      allGroups {
          edges {
            node {
              id
              name
            }
          }
          }
        }`
      axios.post('', { query }).then(response => {
        messageError(response, this)

        const groups = response.data.data.allGroups.edges
        groups.map(element => element.node)

        this.permissionGroups = groups
      }).catch(() => {

      })
    },
    mutateUser() {
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      this.disabled = !this.disabled

      let query = `mutation{
      }`
      if (this.isMultiplatformUser) {
        query = `mutation{
          updateMultiPlatformUser(id:"${this.id}",input:{
          firstName:"${this.firstName}",
          lastName:"${this.lastName}",
          email:"${this.email}",
          }){
            multiPlatformUser {
              id
            }
          }
        }`
        data.append('query', query)
      }
      if (this.isSuperuser) {
        query = `mutation{
          updateProfile(id:"${this.id}",input:{
            firstName:"${this.firstName}"
            lastName:"${this.lastName}"
            email:"${this.email}"
            description:"${this.description}"
            avatar:"${this.imageData}"
            }){
              profile {
                id
              }
            }
        }`
        data.append('query', query)
      }
      if (this.isProfileUser) {
        query = `mutation($description:String, $avatar:Upload
        ){
          updateProfile(id:"${this.id}",input:{
            firstName:"${this.firstName}"
            lastName:"${this.lastName}"
            email:"${this.email}"
            description:$description
            avatar:$avatar
            }){
              profile {
                id
              }
            }
        }`
        const variables = {
          description: this.description,
          avatar: this.imageData,
        }
        data.append('query', query)
        if (this.imageData) data.append('avatar', this.imageData)
        if (this.avatarRemoved) data.append('avatar', null)

        data.append('variables', JSON.stringify(variables))
      }

      axios.post('', data, config).then(result => {
        messageError(result, this)
        showToast(this.$t('success'), 1, this)
        this.disabled = !this.disabled
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
      })
    },
    handleImageUserError(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.src = noImgUser
    },
    checkEmail(rules) {
      if (rules.email) {
        return (this.$t('verifiedEmail'))
      }
      return ''
    },
    changeForm() {
      this.disabled = false
    },

    // Método para abrir el selector de archivos
    selectFile() {
      // Accede al elemento HTML nativo del input de archivo y activa click()
      this.$refs.refInputEl.$el.click()
    },
    handleFileChange(event) {
      const file = event.target.files[0] // Accede al primer archivo seleccionado
      if (file) {
        // Crea un objeto URL para la imagen y la almacena en la variable 'image'
        this.avatarRemoved = false
        this.imageSrc = URL.createObjectURL(file)
      }
    },
    // Método para generar la URL del archivo seleccionado
    generateFileUrl(event) {
      const file = event.target.files[0]
      if (file) {
        this.fileUrl = URL.createObjectURL(file)
      }
    },
    removeAvatar() {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('imgDelete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.avatar = null
          this.imageData = null
          this.imageSrc = null
          this.avatarRemoved = true
          this.changeForm()
        } else {
          this.show = false
        }
      }).catch(err => {
        console.log(err)
      })
    },
  },
}
</script>
<style scoped lang="scss"></style>
