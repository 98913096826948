<template>
  <b-card>
    <b-card-header class="d-flex justify-content-start mb-1">
      <span
        class="font-weight-bold"
        style="color: #fefefe; font-size: 16px"
      >{{ $t("NewPassword") }} </span>
    </b-card-header>
    <b-card-body>
      <div>
        <validation-observer ref="passwordRules">
          <b-form
            @input="changeForm()"
            @submit.prevent="validationFormPassword"
          >
            <b-row>
              <b-col>
                <!-- Password -->
                <validation-provider
                  v-slot="validationContext"
                  name="password"
                  vid="Password"
                  rules="min:8|password"
                >
                  <b-form-group
                    :label="$t('administrators.pass')"
                    label-for="password"
                  >
                    <b-input-group>
                      <b-form-input
                        id="password"
                        v-model="password"
                        autocomplete="new-password"
                        :type="passwordFieldType"
                        :placeholder="$t('administrators.pass')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{
                      checkPassword(validationContext.failedRules)
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <!-- c-password -->
                <validation-provider
                  v-slot="validationContext"
                  name="c-password"
                  vid="c-password"
                  rules="required|confirmed:Password"
                >
                  <b-form-group
                    :label="$t('administrators.repass')"
                    label-for="c-password"
                  >
                    <b-input-group>
                      <b-form-input
                        id="c-password"
                        v-model="cpassword"
                        autocomplete="new-password"
                        :type="passwordFieldType"
                        :placeholder="$t('administrators.repass')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{
                      checkConfirmPassword(validationContext.failedRules)
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-end">
              <b-button
                variant="success"
                :disabled="disabled"
                class="mb-1 mb-sm-0  float-right mt-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                <feather-icon icon="SaveIcon" />
                {{ $t("dataGeneric.save") }}
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'

import {
  BCard,
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  VBTooltip,
  BForm,
  BCardHeader,
  BCardBody,
  // BCardFooter,
} from 'bootstrap-vue'
import { required, email, length } from '@validations'
import Ripple from 'vue-ripple-directive' // Notification
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getUserData } from '@/auth/utils'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'

const forbid = false
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    BCardBody,
    // BCardFooter,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    view: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      email,
      forbid,
      required,
      length,
      loginUser: getUserData(),
      disabled: true,
      password: null,
      cpassword: null,
      id: router.currentRoute.params.id,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    if (this.view === 'admin') {
      this.isProfileUser = true
    } else {
      this.isMultiplatformUser = this.loginUser.isMultiplatformUser
      this.isSuperuser = this.loginUser.isSuperuser
      this.isProfileUser = this.loginUser.isProfileUser
    }
  },
  methods: {
    validationFormPassword() {
      return new Promise(resolve => {
        this.$refs.passwordRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
              this.mutateUser()
            }
          })
          .catch(err => console.log(err))
      })
    },
    checkConfirmPassword(rules) {
      if (rules.confirmed) {
        return (this.$t('confirmPassword'))
      }
      return ''
    },
    changeForm() {
      this.disabled = false
    },
    checkPassword(rules) {
      if (rules.min) {
        return (this.$t('pass8Cha'))
      }
      if (rules.password) {
        return (this.$t('passMayus'))
      }
      return ''
    },
    checkEmail(rules) {
      if (rules.email) {
        return (this.$t('verifiedEmail'))
      }
      return ''
    },
    mutateUser() {
      this.disabled = !this.disabled
      let query = `mutation{
      }`
      if (this.isMultiplatformUser) {
        query = `mutation{
          updateMultiPlatformUser(id:"${this.id}",input:{
          password:"${this.password}"
          }){
            multiPlatformUser {
              id
            }
          }
        }`
      }
      if (this.isSuperuser) {
        query = `mutation($password:String){
          updateCustomuser(id:"${this.id}",input:{
          password:$password
          }){
            customUser {
              id
            }
          }
        }`
      }
      if (this.isProfileUser) {
        query = `mutation($password:String){
          updateProfile(id:"${this.id}",input:{
          password:$password
          }){
            profile {
              id
            }
          }
        }`
      }

      axios.post('', { query, variables: { password: this.password } }).then(result => {
        messageError(result, this)
        showToast(this.$t('success'), 1, this)
        this.disabled = !this.disabled
        this.password = ''
        this.cpassword = ''
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
      })
    },
  },
}
</script>
