<template>
  <section id="final-users-v2">
    <b-row>
      <b-col>
        <h1>{{ $t("Profile") }}</h1>
        <h5 class="text-primary">
          {{ $t("editProfile") }}
        </h5>
      </b-col>
    </b-row>
    <div class="mt-2">
      <b-tabs
        vertical
        content-class="col-12 col-md-10 mt-1 mt-md-0"
        pills
        lazy
        nav-wrapper-class="col-md-2 col-12"
        nav-class="nav-left"
      >
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t("User") }}</span>
          </template>
          <account-setting-general />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="LockIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t('changPass') }}</span>
          </template>

          <account-setting-password />
        </b-tab>
        <b-tab
          v-if="!checkPermissions('users.dont_need_2fa')"
          lazy
        >
          <template #title>
            <feather-icon
              icon="SmartphoneIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t('twoFactor.vincular') }}</span>
          </template>
          <account-setting-2fa />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t("administrators.info") }}</span>
          </template>

          <account-setting-information />
        </b-tab>
        <b-tab v-if="isMultiplatformUser">
          <template #title>
            <i :class="'fa-light fa-1x fa-files'" />
            <span class="font-weight-bold">{{ $t("FIL") }}</span>
          </template>

          <account-multiplatform-user />
        </b-tab>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import {
  BTabs,
  BTab,
  BCol,
  BRow,
} from 'bootstrap-vue'
import { checkPermissions } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import AccountSettingGeneral from '../setting/UserSettingGeneral.vue'
import AccountSettingPassword from '../setting/UserSettingPassword.vue'
import AccountSetting2fa from './AccountSetting2fa.vue'
import AccountSettingInformation from '../setting/UserSettingInformation.vue'
import AccountMultiplatformUser from './AccountMultiplatformUser.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCol,
    BRow,
    AccountSettingGeneral,
    AccountMultiplatformUser,
    AccountSetting2fa,
    AccountSettingPassword,
    AccountSettingInformation,
  },
  data() {
    return {
      checkPermissions,
      isMultiplatformUser: false,
    }
  },
  mounted() {
    this.isMultiplatformUser = getUserData().isMultiplatformUser
  },

}
</script>
