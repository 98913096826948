<template>
  <b-card>
    <b-card-header class="d-flex justify-content-start">
      <span
        class="font-weight-bold"
        style="color: #fefefe; font-size: 16px"
      >{{ $t('ActvDetail') }}</span>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('administrators.date')"
            label-for="mc-dateJoined-name"
          >
            <strong>
              {{ checkDate(dateJoined) }}
            </strong>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('administrators.last')"
            label-for="mc-lastLogin-name"
          >
            <strong>
              {{ checkDate(lastLogin) }}
            </strong>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import router from '@/router'
import {
  BFormGroup,
  BRow,
  BCol,
  BCard,
  VBTooltip,
  BCardHeader,
  BCardBody,

} from 'bootstrap-vue'
import { messageError } from '@/store/functions'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  props: {
    view: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dateJoined: null,
      lastLogin: null,
      id: router.currentRoute.params.id,
      isMultiplatformUser: false,
      loginUser: getUserData(),
      isSuperuser: false,
      isProfileUser: false,
    }
  },
  mounted() {
    if (this.view === 'admin') {
      this.isProfileUser = true
      this.getProfileUser()
    } else {
      this.isMultiplatformUser = this.loginUser.isMultiplatformUser
      this.isSuperuser = this.loginUser.isSuperuser
      this.isProfileUser = this.loginUser.isProfileUser
      if (this.isMultiplatformUser) {
        this.getMultiplatformUser()
      }
      if (this.isSuperuser) {
        this.getcustomUser()
      }
      if (this.isProfileUser) {
        this.getProfileUser()
      }
    }
  },

  methods: {
    getcustomUser() {
      const query = `{
        allCustomUsers(id: "${this.id}") {
          edges {
            node {
              id
              isActive
              dateJoined
              lastLogin                          
                
          }
        }
        }
      }`
      axios.post('', { query }).then(result => {
        messageError(result, this)

        const userData = result.data.data.allCustomUsers.edges[0].node

        this.dateJoined = userData.dateJoined
        this.lastLogin = userData.lastLogin
      }).catch(err => {
        console.log(err)
      })
    },
    getMultiplatformUser() {
      const query = `{
        allMultiplatformUsers(id: "${this.id}") {
          edges {
            node {
              id
              isActive
              dateJoined
              lastLogin                          
               
          }
        }
        }
      }`
      axios.post('', { query }).then(result => {
        messageError(result, this)
        console.log(result)

        const userData = result.data.data.allMultiplatformUsers.edges[0].node
        console.log(userData)

        this.dateJoined = userData.dateJoined
        this.lastLogin = userData.lastLogin
      }).catch(err => {
        console.log(err)
      })
    },
    getProfileUser() {
      const query = `{
        allProfiles(id: "${this.id}") {
          edges {
            node {
              id
              isActive
              dateJoined
              lastLogin                          
          }
        }
        }
      }`
      axios.post('', { query }).then(result => {
        messageError(result, this)

        const userData = result.data.data.allProfiles.edges[0].node
        this.dateJoined = userData.dateJoined
        this.lastLogin = userData.lastLogin
      }).catch(err => {
        console.log(err)
      })
    },
    checkDate(value) {
      // Verifica si el valor es nulo, indefinido o si el objeto Date es inválido
      if (!value || Number.isNaN(new Date(value).getTime())) {
        return 'Fecha no válida'
      }

      // Construye el idioma con la configuración de i18n
      const language = `${this.$i18n.locale}-${this.$i18n.locale.toUpperCase()}`

      // Crea el objeto Date
      const date = new Date(value)

      // Configura las opciones de formato
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }

      // Formatea la fecha con Intl.DateTimeFormat
      const dateFormat = new Intl.DateTimeFormat(language, options)
      return dateFormat.format(date)
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
